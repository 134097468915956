import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { ReactNode } from 'react'

export type NoPasswordManagerProps = {
  children: ReactNode
  sx?: SxProps
}

export function NoPasswordManager({ children, sx }: NoPasswordManagerProps): JSX.Element {
  return (
    <Box className="nodash" sx={sx}>
      {children}
    </Box>
  )
}
